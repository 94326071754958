import ErrorCode from './error_code';

export default class Color {
  public id: number;

  public name: string;

  constructor(id = 0, name = '') {
    this.id = id;
    this.name = name;
  }

  public async save(itemId: number, newColor: boolean): Promise<Color> {
    let url: string;
    if (newColor) {
      url = `${process.env.VUE_APP_BACKEND_API_URL}/items/${itemId}/colors`;
    } else {
      url = `${process.env.VUE_APP_BACKEND_API_URL}/items/${itemId}/colors/${this.id}`;
    }
    const resp = await fetch(url, {
      method: newColor ? 'POST' : 'PUT',
      body: JSON.stringify(this),
    credentials: 'include',
    });
    const json = await resp.json();
    return json;
  }

  public async delete(itemId: number): Promise<ErrorCode> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/items/${itemId}/colors/${this.id}`;
    const resp = await fetch(url, {
      method: 'DELETE',
    credentials: 'include',
    });
    if (!resp.ok) {
      const text = await resp.text()
      return new ErrorCode(text);
    }
      return null;
  }
}
