export default class Log {
  public method: string;
  public access_time: Date;
  public path: string;
  public user_id: number;
  public user_name: string;
  public entry: string;

  constructor(method, access_time, path, user_id, user_name, entry) {
    this.method = method;
    this.access_time = access_time;
    this.path = path;
    this.user_id = user_id;
    this.user_name = user_name;
    this.entry = entry;
  }
}

export async function loadLogs(): Promise<Log[]> {
  const logs = new Array<Log>();
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/logs`;
  const resp = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  const json = await resp.json();
  json.forEach((l: Log) => {
    logs.push(
      new Log(
        l.method,
        l.access_time,
        l.path,
        l.user_id,
        l.user_name,
        l.entry
      ),
    );
  });
  return logs;
}
