import Color from './Color';

export class Item {
  public id: number;

  public description: string;

  public unit: string;

  public itemsToCheck: number;

  public itemsToCheckFrom: number;

  public testPlanId: number;

  public colors: Color[];

  public itemChecks: string;

  public test: string;

  constructor(
    id: number = null,
    description = '',
    unit = '',
    itemsToCheck = 0,
    itemsToCheckFrom = 0,
    testPlanId = 0,
    colors: Color[] = new Array<Color>(),
  ) {
    this.id = id;
    this.description = description;
    this.unit = unit;
    this.itemsToCheck = itemsToCheck;
    this.itemsToCheckFrom = itemsToCheckFrom;
    this.testPlanId = testPlanId;
    this.colors = colors;
    this.itemChecks = `${itemsToCheck}/${itemsToCheckFrom}`;
  }

  public async save(newItem: boolean): Promise<Item> {
    let url: string;
    if (newItem) {
      url = `${process.env.VUE_APP_BACKEND_API_URL}/items`;
    } else {
      url = `${process.env.VUE_APP_BACKEND_API_URL}/items/${this.id}`;
    }
    const resp = await fetch(url, {
      method: newItem ? 'POST' : 'PUT',
      body: JSON.stringify(this),
      credentials: 'include',
    });
    if (resp.ok) {
      const json = await resp.json();
      const { id, description, unit, itemsToCheck, itemsToCheckFrom, testPlanId, colors } = json;
      const colorsTmp: Color[] = [];
      if (colors !== null) {
        colors.forEach((c: Color) => {
          colorsTmp.push(new Color(c.id, c.name));
        });
      }
      return new Item(id, description, unit, itemsToCheck, itemsToCheckFrom, testPlanId, colorsTmp);
    } else {
      return null;
    }
  }

  public async delete(): Promise<boolean> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/items/${this.id}`;
    const resp = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
    });
    return resp.ok;
  }
}

function convertJsonToItem(json: Item): Item {
  const { id, description, unit, itemsToCheck, itemsToCheckFrom, testPlanId, colors } = json;
  const colorsTmp: Color[] = [];
  if (colors !== null) {
    colors.forEach((c: Color) => {
      colorsTmp.push(new Color(c.id, c.name));
    });
  }
  return new Item(id, description, unit, itemsToCheck, itemsToCheckFrom, testPlanId, colorsTmp);
}

export async function loadItem(itemId: number): Promise<Item> {
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/items/${itemId}`;
  const res = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  if (res.ok) {
    const json = await res.json();
    return convertJsonToItem(json);
  }
  return null;
}

export async function loadItems(): Promise<Item[]> {
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/items`;
  const items = new Array<Item>();
  const resp = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  const json = await resp.json();
  json.forEach((element: Item) => {
    items.push(convertJsonToItem(element));
  });
  return items;
}
