export default class MeasuredPKValues {
    public index: number;
    public value: number;
    public approved: boolean;
    public lowerValue: number;
    public upperValue: number;
    public setpointValue: number;
    public picture: URL;
    public visual: boolean;

    constructor(
        index = 0.0,
        value = 0.0,
        approved = false,
        lowerValue = 0.0,
        upperValue = 0.0,
        setpointValue = 0.0,
        picture = null,
        visual = false,
    ) {
        this.index = index;
        this.value = value;
        this.approved = approved;
        this.lowerValue = lowerValue;
        this.upperValue = upperValue;
        this.setpointValue = setpointValue;
        this.picture = picture;
        this.visual = visual;
    }
}