import Vue from 'vue';
import Vuex from 'vuex';
import User from './models/User';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

export interface State {
  user: User
}

const store = new Vuex.Store<State>({
  state: {
    user: new User(),
  },
  mutations: {
    setUser(state, user: User) {
      state.user = user;
    },
  },
  actions: {},
  plugins: [new VuexPersistence().plugin],
});

export default store;
