import PKValues from './PKValues';
import clone from 'lodash.clone';
import axios from 'axios';

export default class TestPlan {
  public id: number;
  public revision: number;
  public numberOfPKs: number;
  public revisionDate: Date;
  public picture: URL;
  public frontImage: URL;
  public leftImage: URL;
  public backImage: URL;
  public rightImage: URL;
  public values: PKValues[];

  constructor(
    id: number = null,
    revision: number = null,
    revisionDate: Date = null,
    numberOfPKs = 0,
    picture: URL = null,
    values: PKValues[] = null,
    frontImage: URL = null,
    leftImage: URL = null,
    backImage: URL = null,
    rightImage: URL = null,
  ) {
    this.id = id;
    this.revision = revision;
    this.revisionDate = revisionDate;
    this.numberOfPKs = numberOfPKs;
    this.picture = picture;
    this.values = values;
    if (!this.values) {
      this.values = new Array<PKValues>();
    }
    this.frontImage = frontImage;
    this.leftImage = leftImage;
    this.backImage = backImage;
    this.rightImage = rightImage;
  }

  public async getAvailableRevisions(): Promise<number[]> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/testPlans/${this.id}/revisions`;
    const resp = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    const json = await resp.json();
    return json;
  }

  public async save(newRevision: boolean): Promise<TestPlan> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/testPlans/${this.id}/revisions${
      !newRevision ? `/${this.revision}` : ''
    }`;
    const testPlan = clone(this);
    testPlan.revisionDate = this.revisionDate ? this.revisionDate : new Date().toISOString();
    const resp = await fetch(url, {
      method: newRevision ? 'POST' : 'PUT',
      body: JSON.stringify(testPlan),
      credentials: 'include',
    });
    if (resp.ok) {
      const json = await resp.json();
      return new TestPlan(
        json.id,
        json.revision,
        json.revisionDate,
        json.numberOfPKs,
        json.picture,
        json.values,
      );
    }
  }

  public async clean(): Promise<boolean> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/testPlans/${this.id}/revisions/${this.revision}/clean`;
    const resp = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (resp.ok) {
      return true;
    }
    return false;
  }

  public async create(): Promise<TestPlan> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/testPlans`;
    const testPlan = clone(this);
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(testPlan),
      credentials: 'include',
    });
    if (resp.ok) {
      const json = await resp.json();
      const values = new Array<PKValues>();
      json.values.forEach((val: PKValues) => {
        const pkVal = new PKValues(
          val.index,
          val.setpointValue,
          val.upperValue,
          val.lowerValue,
          val.visual,
          val.picture,
        );
        values.push(pkVal);
      });
      return new TestPlan(
        json.id,
        json.revision,
        json.revisionDate,
        json.numberOfPKs,
        json.picture,
        values,
      );
    } else {
      throw await resp.text();
    }
  }
}

function jsonToTestPlan(json: TestPlan): TestPlan {
  const values = new Array<PKValues>();
  json.values.forEach((val: PKValues) => {
    const pkVal = new PKValues(
      val.index,
      val.setpointValue,
      val.upperValue,
      val.lowerValue,
      val.visual,
      val.picture,
    );
    values.push(pkVal);
  });
  return new TestPlan(
    json.id,
    json.revision,
    json.revisionDate,
    json.numberOfPKs,
    json.picture,
    values,
    json.frontImage,
    json.leftImage,
    json.backImage,
    json.rightImage,
  );
}

export async function loadTestPlans(): Promise<TestPlan[]> {
  const testPlans = new Array<TestPlan>();
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/testPlans?revision=latest`;
  const resp = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  const json = await resp.json();
  json?.forEach((t: TestPlan) => {
    testPlans.push(jsonToTestPlan(t));
  });
  return testPlans;
}

export async function loadTestPlanByIdAndRevision(id: number, revision: number): Promise<TestPlan> {
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/testPlans/${id}/revisions/${revision}`;
  const resp = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  if (resp.ok) {
    const json = await resp.json();
    return jsonToTestPlan(json);
  }
  return null;
}

export async function loadTestPlanById(id: number): Promise<TestPlan[]> {
  const testPlans = new Array<TestPlan>();
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/testPlans/${id}`;
  const resp = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  const json = await resp.json();
  json.forEach((t: TestPlan) => {
    testPlans.push(jsonToTestPlan(t));
  });
  return testPlans;
}

export async function uploadPKPicture(
  id: number,
  revision: number,
  pkID: number,
  file: File,
): Promise<string> {
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/testPlans/${id}/revisions/${revision}/pictures/${pkID}`;
  const formData = new FormData();
  formData.append('file', file);
  try {
    const resp = await axios.post(url, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp.data;
  } catch (error) {
    return error;
  }
}

export async function uploadViewPicture(
  id: number,
  revision: number,
  view: string,
  file: File,
): Promise<string> {
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/testPlans/${id}/revisions/${revision}/pictures`;
  const formData = new FormData();
  formData.append('file', file);
  try {
    const resp = await axios.post(url, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        view,
      },
    });
    return resp.data;
  } catch (error) {
    return error;
  }
}

