var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Bestellungen")]),_c('v-data-table',{key:_vm.reRenderTable,attrs:{"headers":_vm.headers,"items":_vm.orders,"search":_vm.search,"sort-by":"id","footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
    }},on:{"dblclick:row":_vm.rowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-btn',{staticStyle:{"margin":"10px 0 0 20px"},attrs:{"x-small":"","depressed":"","fab":"","color":"primary"},on:{"click":_vm.addOrder}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',[_c('v-text-field',{staticStyle:{"margin":"0 20px"},attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openMeasuredValues(item)}}},[_vm._v(" mdi-google-analytics ")])]}}],null,true)}),_c('OrderView',{attrs:{"selectedOrder":_vm.selectedOrder,"newOrder":_vm.newOrder,"show":_vm.show},on:{"close-order":_vm.closeOrder}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }