var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Prüfwerte")]),_c('v-card-text',[_c('v-row',{staticStyle:{"margin":"0"},attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"label":"Bestellnummer","disabled":""},model:{value:(_vm.order.id),callback:function ($$v) {_vm.$set(_vm.order, "id", $$v)},expression:"order.id"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"label":"Revisionsstand Prüfplan","disabled":""},model:{value:(_vm.order.testPlanRevision),callback:function ($$v) {_vm.$set(_vm.order, "testPlanRevision", $$v)},expression:"order.testPlanRevision"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"label":"Artikel","disabled":""},model:{value:(_vm.order.itemId),callback:function ($$v) {_vm.$set(_vm.order, "itemId", $$v)},expression:"order.itemId"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"label":"Freigabedatum","disabled":""},model:{value:(_vm.order.releaseDate),callback:function ($$v) {_vm.$set(_vm.order, "releaseDate", $$v)},expression:"order.releaseDate"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":_vm.releaseColor},on:{"click":_vm.changeOrderRelease}},[_vm._v(" "+_vm._s(_vm.releaseText)+" ")])],1)],1)],1),_c('v-data-table',{key:_vm.reRenderTable,attrs:{"headers":_vm.headers,"items":_vm.measuredValues,"search":_vm.search,"sort-by":"index","footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
    }},on:{"dblclick:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.release",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getReleaseColor(item.release),"dark":""}})]}},{key:"top",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-btn',{staticStyle:{"margin":"10px 0 0 20px"},attrs:{"x-small":"","depressed":"","fab":"","color":"primary"},on:{"click":_vm.addMeasuredValue}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',[_c('v-text-field',{staticStyle:{"margin":"0 20px"},attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Wollen Sie die Prüfwerte wirklich löschen?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteMVConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteMV(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}),_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error","dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMsg)+" ")]),_c('MeasuredValuesView',{attrs:{"selectedMeasuredValue":_vm.selectedMeasuredValue,"isNewMeasuredValue":_vm.newMeasuredValue,"show":_vm.show},on:{"close-measured-value":_vm.closeMeasuredValue}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }