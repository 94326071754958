import clone from 'lodash.clone';
import ErrorCode from './error_code';
import { Item, loadItem } from './Item';

export default class Order {
  public id: number;

  public orderDate: string;

  public deliveryDate: string;

  public releaseDate: string;

  public itemId: number;

  public testPlanRevision: number;

  public itemColorId: number;

  public item: Item;

  public itemQuantity: number;

  constructor(
    id: number = null,
    orderDate: string = new Date().toISOString().substr(0, 10),
    deliveryDate: string = new Date().toISOString().substr(0, 10),
    itemId: number = null,
    testPlanRevision: number = null,
    itemColorId: number = null,
    itemQuantity: number = null,
    releaseDate = '',
  ) {
    this.id = id;
    this.orderDate = orderDate;
    this.deliveryDate = deliveryDate;
    this.itemId = itemId;
    this.testPlanRevision = testPlanRevision;
    this.itemColorId = itemColorId;
    this.itemQuantity = itemQuantity;
    this.releaseDate = releaseDate;
    if (itemId !== undefined && itemId !== null && itemId > 0) {
      this.item = new Item(itemId);
    } else {
      this.item = new Item();
    }
  }

  public async delete(): Promise<boolean> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/${this.id}`;
    const resp = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
    });
    return resp.ok;
  }

  public async changeRelease(): Promise<{ order: Order; error: ErrorCode }> {
    if (this.releaseDate) {
      this.releaseDate = null;
    } else {
      this.releaseDate = new Date().toISOString().substring(0, 10);
    }
    return this.save(false);
  }

  public async save(newOrder: boolean): Promise<{ order: Order; error: ErrorCode }> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders${!newOrder ? `/${this.id}` : ''}`;
    const order = clone(this);
    order.orderDate = this.orderDate ? new Date(this.orderDate).toISOString() : null;
    order.deliveryDate = this.deliveryDate ? new Date(this.deliveryDate).toISOString() : null;
    order.releaseDate = this.releaseDate ? new Date(this.releaseDate).toISOString() : null;
    const resp = await fetch(url, {
      method: newOrder ? 'POST' : 'PUT',
      body: JSON.stringify(order),
      credentials: 'include',
    });
    if (resp.ok) {
      if (newOrder) {
        const json = await resp.json();
        const orderDate = json.orderDate
          ? new Date(json.orderDate).toISOString().substr(0, 10)
          : '';
        const deliveryDate = json.deliveryDate
          ? new Date(json.deliveryDate).toISOString().substr(0, 10)
          : '';
        const releaseDate = json.releaseDate
          ? new Date(json.releaseDate).toISOString().substr(0, 10)
          : '';
        return {
          order: new Order(
            json.id,
            orderDate,
            deliveryDate,
            json.itemId,
            json.testPlanRevision,
            json.itemColorId,
            json.itemQuantity,
            releaseDate,
          ),
          error: null,
        };
      }
      return {order: this, error: null};
    }
    const text = await resp.text();
    const error = new ErrorCode(text);
    return { order: null, error: error };
  }

  public async loadItem() {
    this.item = await loadItem(this.itemId);
    return this.item;
  }
}

function jsonToOrder(json: Order): Order {
  const orderDate = json.orderDate ? new Date(json.orderDate).toISOString().substr(0, 10) : '';
  const deliveryDate = json.deliveryDate
    ? new Date(json.deliveryDate).toISOString().substr(0, 10)
    : '';
  const releaseDate = json.releaseDate
    ? new Date(json.releaseDate).toISOString().substr(0, 10)
    : '';
  return new Order(
    json.id,
    orderDate,
    deliveryDate,
    json.itemId,
    json.testPlanRevision,
    json.itemColorId,
    json.itemQuantity,
    releaseDate,
  );
}

export async function loadOrders(): Promise<Order[]> {
  const orders = new Array<Order>();
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders`;
  const resp = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  const json = await resp.json();
  json.forEach((o: Order) => {
    orders.push(jsonToOrder(o));
  });
  return orders;
}

export async function loadOrder(orderNumber: number): Promise<Order> {
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/${orderNumber}`;
  const resp = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  const json = await resp.json();
  return jsonToOrder(json);
}
