import Vue from 'vue';
import Router from 'vue-router';
import OrderTable from './views/Orders/OrderTable.vue';
import MeasuredValuesTable from './views/Orders/MeasuredValuesTable.vue';
import ItemsTable from './views/Items/ItemsTable.vue';
import TestPlanTable from '@/views/Testplan/TestPlanTable.vue';
import Login from './views/LoginView.vue';
import Impressum from './views/ImpressumView.vue';
import Admin from './views/AdminView.vue';
import Logs from './views/LogView.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/orders',
      name: 'Orders',
      component: OrderTable,
    },
    {
      path: '/items',
      name: 'Items',
      component: ItemsTable,
    },
    {
      path: '/testplans',
      name: 'TestPlans',
      component: TestPlanTable,
    },
    {
      path: '/measuredvalues',
      name: 'measuredvalues',
      component: MeasuredValuesTable,
    },
    {
      path: '/impressum',
      name: 'impressum',
      component: Impressum,
    },
    {
      path: '/logs',
      name: 'logs',
      component: Logs,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/AboutView.vue'),
    },
    {
      path: '*',
      redirect: '/orders',
    },
  ],
});
