export default class User {
  public id: number;
  public username: string;
  public division: string;
  public admin: boolean;
  public password: string;

  constructor(id = 0, username = '', division = '', admin = false, password = "") {
    this.id = id;
    this.username = username;
    this.division = division;
    this.admin = admin;
    this.password = password;
  }

  public async resetPassword(password: string): Promise<boolean> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users/${this.id}/password`;
    const resp = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: password,
    });
    if (resp.ok) {
      return true;
    }
    return false;
  }

  public async save(): Promise<User> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users/${this.id}`;
    const resp = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(this),
    });
    if (resp.ok) {
      const json = await resp.json();
      return new User(json.id, json.username, json.division, json.admin);
    }
    return null;
  }

  public async delete(): Promise<boolean> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users/${this.id}`;
    const resp = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (resp.ok) {
        return true;
    }
    return false;
  }
}

function jsonToUser(json: User): User {
    return new User(json.id, json.username, json.division, json.admin);
}

export async function createUser(user: User): Promise<User> {
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/users`;
  const resp = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(user),
  });
  if (resp.ok) {
    const json = await resp.json();
    return jsonToUser(json)
  }
  return null;
}

export async function loadUsers(): Promise<User[]> {
    const orders = new Array<User>();
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/users`;
    const resp = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    const json = await resp.json();
    json.forEach((u: User) => {
      orders.push(jsonToUser(u));
    });
    return orders;
  }
