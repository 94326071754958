import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

Vue.config.productionTip = false;


router.beforeEach(async (to, from, next) => {
  if (document.cookie.indexOf('OBSERVA_SESSION_TOKEN=') !== -1) {
    next();
  } else if (to.name === 'login' || to.name === 'impressum') {
    next();
  } else {
    next('/login');
  }
});

extend('required', {
  ...required,
  message: 'This field is required'
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  components: {
    ValidationProvider
  },
}).$mount('#app');
