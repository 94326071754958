export default class PKValues {
    public index: number;
    public setpointValue: number;
    public upperValue: number;
    public lowerValue: number;
    public visual: boolean;
    public picture: URL;

    constructor(
        index = 0.0,
        setpointValue = 0.0,
        upperValue = 0.0,
        lowerValue = 0.0,
        visual = false,
        picture: URL = null,
    ) {
        this.index = index;
        this.setpointValue = setpointValue;
        this.upperValue = upperValue;
        this.lowerValue = lowerValue;
        this.visual = visual;
        this.picture = picture;
    }
}