import MeasuredPKValue from './MeasuredPKValue';
import clone from 'lodash.clone';
import TestPlan, { loadTestPlanByIdAndRevision } from './Testplan';
import PKValues from './PKValues';

export default class MeasuredData {
  public orderNumber: number;
  public index: number;
  public user: string;
  public division: string;
  public counter: number;
  public cavity: number;
  public release: boolean;
  public comment: string;
  public date: Date;
  public materialcharge: string;
  public machine: string;
  public approval: string;
  public measuredValues: MeasuredPKValue[];
  public pruefplanNumber: number;
  public pruefplanRevision: number;
  public itemNumber: number;
  public itemDescription: string;
  public frontImage: URL;
  public leftImage: URL;
  public backImage: URL;
  public rightImage: URL;

  constructor(
    orderNumber: number = null,
    index: number = null,
    user: string = null,
    division: string = null,
    counter: number = null,
    cavity: number = null,
    release: boolean = null,
    comment: string = null,
    date: Date = null,
    materialcharge: string = null,
    machine: string = null,
    approval: string = null,
    measuredValues: MeasuredPKValue[] = null,
    pruefplanNumber: number = null,
    pruefplanRevision: number = null,
    itemNumber: number = null,
    itemDescription: string = null,
    frontImage: URL = null,
    leftImage: URL = null,
    backImage: URL = null,
    rightImage: URL = null,
  ) {
    this.orderNumber = orderNumber;
    this.index = index;
    this.user = user;
    this.division = division;
    this.counter = counter;
    this.cavity = cavity;
    this.release = release;
    this.comment = comment;
    this.date = date;
    this.materialcharge = materialcharge;
    this.machine = machine;
    this.approval = approval;
    this.measuredValues = measuredValues;
    this.pruefplanNumber = pruefplanNumber;
    this.pruefplanRevision = pruefplanRevision;
    this.itemNumber = itemNumber;
    this.itemDescription = itemDescription;
    this.frontImage = frontImage;
    this.leftImage = leftImage;
    this.backImage = backImage;
    this.rightImage = rightImage;
  }

  public async delete(): Promise<boolean> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/${this.orderNumber}/measuredData/${this.index}`;
    const resp = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (resp.ok) {
      return true;
    } else {
      return false;
    }
  }

  public async save(newMeasuredValue: boolean): Promise<MeasuredData> {
    const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/${this.orderNumber}/measuredData${
      !newMeasuredValue ? `/${this.index}` : ''
    }`;
    const measuredData = clone(this);
    const resp = await fetch(url, {
      method: newMeasuredValue ? 'POST' : 'PUT',
      body: JSON.stringify(measuredData),
      credentials: 'include',
    });
    if (resp.ok) {
      const json = await resp.json();
      const values = new Array<MeasuredPKValue>();
      json.measuredValues.forEach((val: MeasuredPKValue) => {
        const pkVal = new MeasuredPKValue(val.index, val.value, val.approved);
        values.push(pkVal);
      });
      return new MeasuredData(
        json.orderNumber,
        json.index,
        json.user,
        json.division,
        json.counter,
        json.cavity,
        json.release,
        json.comment,
        json.date,
        json.materialcharge,
        json.machine,
        json.approval,
        values,
        json.pruefplan,
        json.pruefplanRevision,
        json.itemNumber,
        json.itemDescription,
      );
    }
  }

  public loadPKValues(testPlan: TestPlan): void {
    this.measuredValues.forEach((mv) => {
      testPlan.values.forEach((pk: PKValues) => {
        if (mv.index === pk.index) {
          mv.lowerValue = pk.lowerValue;
          mv.upperValue = pk.upperValue;
          mv.setpointValue = pk.setpointValue;
          mv.picture = pk.picture;
          mv.visual = pk.visual;
        }
      });
    });
  }
}

function jsonToMeasuredData(json: MeasuredData): MeasuredData {
  const values = new Array<MeasuredPKValue>();
  json.measuredValues.forEach((val: MeasuredPKValue) => {
    const pkVal = new MeasuredPKValue(val.index, val.value, val.approved);
    values.push(pkVal);
  });
  return new MeasuredData(
    json.orderNumber,
    json.index,
    json.user,
    json.division,
    json.counter,
    json.cavity,
    json.release,
    json.comment,
    json.date,
    json.materialcharge,
    json.machine,
    json.approval,
    values,
    json.pruefplanNumber,
    json.pruefplanRevision,
    json.itemNumber,
    json.itemDescription,
  );
}

export async function loadMeasuredValues(ordernumber: number): Promise<MeasuredData[]> {
  const measuredValues = new Array<MeasuredData>();
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/${ordernumber}/measuredData`;
  const resp = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  const json = await resp.json();
  for (const t of json) {
    const md = jsonToMeasuredData(t);
    const tp = await loadTestPlanByIdAndRevision(
      md.pruefplanNumber,
      md.pruefplanRevision,
    );
    if (!tp) {
      return null;
    }
    md.frontImage = tp.frontImage;
    md.backImage = tp.backImage;
    md.leftImage = tp.leftImage;
    md.rightImage = tp.rightImage;
    // HACK: improve x requests -> 1
    md.loadPKValues(tp);
    measuredValues.push(md);
  }
  return measuredValues;
}

export async function loadTestMeasuredValueByOrderIDAndIndex(
  ordernumber: number,
  index: number,
): Promise<MeasuredData> {
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/${ordernumber}/measuredData/${index}`;
  const resp = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  const json = await resp.json();
  return jsonToMeasuredData(json);
}

export async function loadTestMeasuredValueNextApprovalByOrder(
  ordernumber: number,
): Promise<string> {
  const url = `${process.env.VUE_APP_BACKEND_API_URL}/orders/${ordernumber}/measuredData/nextApproval`;
  const resp = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  if (resp.ok) {
    return resp.text();
  }
  return 'A';
}
